.modal-backdrop {
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;

  .modal {
    z-index: 9999;
    position: fixed;
    top: 10%;
    left: 10%;
    width: calc(80% - 30px);
    margin: 0 auto;
    background-color: $white;
    border: 1px solid $light_blue;
    border-radius: 10px;
    padding: 15px;
    max-height: 80%;
    overflow: scroll;
    overscroll-behavior: contain;

    &.full {
      z-index: 9999;
      left: 0%;
      width: 100%;
      top: 0%;
      height: 100%;
      max-height: 100%;

      .close-btn {
        top: 0px;
        right: 0px;
      }
    }

    &.medium {
      left: 30%;
      width: calc(40% - 30px);
      .close-btn {
        right: 28%;
      }
    }

    .close-btn {
      position: fixed;
      right: 8%;
      top: 7%;
      border-radius: 50px;
      width: 40px;
      height: 40px;
      background-color: $dark_blue;
      color: $light_blue;
    }

    .modal-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .modal-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      flex-wrap: wrap;
      width: 100%;
      padding: 20px;
    }

    .modal-content {
      padding: 20px;
    }

    .modal-footer {
      text-align: right;
      button {
        margin-left: 20px;
      }
    }
  }
}
