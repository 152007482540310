// Header
$black: #111827;
$green: #a2ce98;
$light_blue: #cae6ea;
$red: #ec547b;
$orange: #ecb254;
$blue: #66b8f2;
$light_gray: #e9eaeb;
$gray: #ababab;
$dark_blue: #2a2859;
$white: #fff;

// Sizes
$mobile_size: 425px;
$tablet_size: 768px;
$desktop_size: 1024px;

// Fonts
$font_title: Sansation;
$font_body: Lato;

// Sansation
@font-face {
  font-family: "Sansation";
  font-style: normal;
  font-weight: 400;
  src: url(fonts/sansation/Sansation_Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Sansation";
  font-style: normal;
  font-weight: 700;
  src: url(fonts/sansation/Sansation_Bold.ttf) format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap");
