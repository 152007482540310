html,
body {
  margin: 0;
  padding: 0;
  font-family: $font_body;
  color: $dark_blue;
  width: 100%;
}

h1 {
  font-family: $font_title;
}

h2,
h3 {
  font-family: $font_body;
}

a,
a:visited {
  color: $dark_blue;
  text-decoration: none;

  &.button {
    color: $white;

    &.link {
      color: $dark_blue;
    }
  }
}

.link {
  color: $blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.flip {
  -moz-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-60 {
  margin-bottom: 60px;
}

.admin-container {
  padding: 90px;
  @media screen and (max-width: $mobile_size) {
    padding: 20px;
  }
  padding-top: 0px;
}

.btn-bar-right {
  text-align: right;
  button {
    margin-left: 15px;

    &.link {
      padding: 0px;
    }
  }
}

.btn-bar {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
  text-align: left;

  button,
  .button {
    margin-right: 15px;
    display: inline-block;
  }

  .btn-bar-left {
    display: flex;
  }

  .btn-bar-right {
    display: flex;
  }
}

.loading {
  margin-top: 10px;
}

.container-markdown {
  padding-top: 15px;
}

.back-link,
.back-link:visited {
  color: $dark_blue;
  margin-bottom: 10px;
  display: block;
  display: inline-block;
  margin-right: 6px;
  top: 6px;
  position: relative;
}

.settings-container {
  padding: 90px;
  @media screen and (max-width: $mobile_size) {
    padding: 20px;
  }

  padding-top: 0px;

  h2 {
    margin-top: 40px;
  }
}

.content-center {
  width: 100%;
  text-align: center;
}

.align-left {
  text-align: left;
}

a.hover,
.hover {
  &:hover {
    background-color: $light_blue;
    .description {
      text-decoration: underline;
    }
  }
}

.modal-img-select {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: flex-start;

  .img-select-container {
    margin-bottom: 10px;
    margin-right: 10px;
    width: 32%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    background-color: $light_gray;
    cursor: pointer;
    align-items: center;
    border: 1px solid transparent;
    position: static;
    flex-direction: column;

    .btn-star {
      position: relative;
      top: 5px;
      left: 5px;
      position: relative;
      top: 5px;
      left: calc(-50% + 30px);
    }

    &:hover {
      background-color: #ababab81;
      border: 1px solid $gray;
    }

    img {
      margin-top: -40px;
      max-height: 300px;
      max-width: 100%;
    }
  }
}
