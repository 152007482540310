input,
textarea,
select {
  width: 100%;
  height: 30px;
  padding: 3px 15px;
  border-radius: 10px;
  outline: none;
  border: 1px solid $dark_blue;
  font-size: 16px;
  font-family: $font_body;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $gray;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $gray;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $gray;
  }

  &[type="checkbox"] {
    width: 40px !important;
  }
}

textarea {
  height: 60px;
  padding-top: 7px;
}

button,
.button {
  color: $dark_blue;
  background-color: $light_blue;
  border: none;
  font-family: $font_title;
  padding: 5px 15px;
  border-radius: 10px;
  height: 40px;
  width: fit-content;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
    cursor: initial;
    &:hover {
      opacity: 0.4;
    }
  }

  &:hover {
    opacity: 0.9;
  }

  &.small {
    font-weight: bold;
    font-size: 0.7rem;
    padding: 2px 10px;
    height: 25px;
  }

  &.big {
    font-weight: bold;
    font-size: 1rem;
  }

  &.dark {
    color: $light_blue;
    background-color: $dark_blue;
  }

  &.green {
    color: $dark_blue;
    background-color: $green;
  }

  &.orange {
    color: $white;
    background-color: $orange;
  }

  &.red {
    color: $white;
    background-color: $red;
  }

  &.link {
    color: $dark_blue;
    background-color: transparent;
  }
}

.meta {
  color: $gray;
}

.dropdown {
  .button {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 0px;
    padding-left: 15px;

    &.open {
      border-radius: 10px 10px 0px 0px;
    }

    & > div {
      padding: 0px;
      height: 20px;
      margin-right: 10px;
      font-family: Sansation;
      font-size: 15px;
      display: flex;
    }
  }
  .menu {
    padding: 10px;
    background-color: $light_gray;
    border-radius: 0px 0px 10px 10px;
    position: absolute;

    & > div {
      margin-bottom: 10px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .dropdown-arrow {
    border-left: 1px solid;
    padding-left: 7px !important;
    height: auto !important;

    &:hover {
      opacity: 0.7;
    }
  }
}

.label-row {
  margin-bottom: 5px;
  width: 100%;
  box-sizing: border-box;
  display: flex;

  label {
    width: 200px;
    display: inline-block;
    margin-bottom: 5px;
    margin-top: 5px;
    text-align: left;
  }

  .img-preview-container {
    display: flex;
    flex-direction: column;

    .img-preview {
      max-height: 200px;
      display: block;
      width: fit-content;
    }
  }

  input,
  textarea,
  select {
    width: 100%;
    margin-bottom: 20px;

    &.color-input {
      width: calc(100% - 40px);
      border-radius: 10px 0px 0px 10px;
    }

    &[type="color"] {
      width: 50px;
      height: 38px;
      padding: 0px;
      margin: 0px;
      border-radius: 0px 10px 10px 0px;
      margin-left: -1px;
    }
  }
  .color-wrapper {
    width: 50px;
    height: 36px;
    overflow: hidden;
    border-radius: 0px 10px 10px 0px;
    border: 1px solid $dark_blue;
    border-left: none;

    input[type="color"] {
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      padding: 0px;
      margin: -5px;
      border: none;
    }
  }

  select {
    height: 38px;
    margin-left: -5px;
  }
}

.info-icon-container {
  display: inline-block;
  cursor: pointer;

  .info-icon {
    width: 18px;
    margin-left: 10px;
  }

  .info-icon-text {
    display: none;
    position: absolute;
    background-color: $light_gray;
    border-radius: 5px;
    padding: 5px 10px;
    max-width: 300px;
    color: $black;
    margin-left: 35px;
    margin-top: -35px;
    white-space: pre-line;
  }

  &:hover,
  &:active {
    .info-icon-text {
      display: block;
    }
  }
}

.alert-success {
  background-color: $green;
  border-radius: 10px;
  padding: 5px 10px;
  color: $dark_blue;
  margin-bottom: 20px;
  width: fit-content;
}

.alert-error {
  background-color: $red;
  color: $white;
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 20px;
  width: fit-content;
}

.message,
.error {
  color: $dark_blue;
}

.badge {
  background-color: $gray;
  color: $white;
  font-weight: bold;
  border-radius: 10px;
  padding: 5px 10px;
  width: fit-content;
  display: inline-block;
  font-size: 13px;
  display: flex;
  align-items: center;

  &.status-Published {
    background-color: $green;
    color: $white;
  }

  &.status-Scheduled {
    background-color: $dark_blue;
    color: $white;
  }

  &.status-Notified {
    background-color: $orange;
    color: $white;
  }

  &.type-Story {
    background-color: $green;
    color: $white;
  }

  &.type-Reel {
    background-color: $dark_blue;
    color: $white;
  }

  &.type-Post {
    background-color: $orange;
    color: $white;
  }
}

.filter-container {
  margin-bottom: 20px;
  .filter-row {
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;

    .filter-label {
      width: 100px;
      position: relative;
      bottom: -6px;
    }

    input {
      height: 25px;
    }

    select {
      width: 233px;
    }

    // .filter-data {
    //   select {
    //     width: 150px;
    //   }
    // }
  }
}
