// Content edit
@media screen and (max-width: $tablet_size) {
  .content-edit {
    .wrapper-layout {
      flex-direction: column;
    }

    .modal-edit-wrapper.columns {
      padding: 0px;
      flex-direction: column;

      .modal-edit-wrapper-left {
        margin-top: 30px;
        min-width: 100%;
      }

      .modal-edit-wrapper-right {
        width: 100%;
        max-width: calc(100%);
        padding-left: 0px;
        padding-right: 0px;
        overflow-y: visible;
        position: initial;
      }
    }
  }
}

// Navbar

@media screen and (max-width: $tablet_size) {
  .navbar {
    z-index: 9;

    &.open {
      .navbar-left,
      .navbar-center,
      .navbar-burger-content {
        z-index: 99999;
      }
    }

    .navbar-burger-content {
      display: flex;

      .navbar-burger {
        width: 30px;
        cursor: pointer;
        margin-right: 15px;
      }
    }

    @-webkit-keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .navbar-right {
      display: none;
      transition: opacity 0.1s ease-out;
      opacity: 0;
      -webkit-animation: fadeIn 0.1s;
      animation: fadeIn 0.1s;

      &.open {
        z-index: 999;
        opacity: 1;

        display: flex;
        position: absolute;
        top: 0px;
        background-color: white;
        width: calc(100% - 30px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
        padding-top: 58px;
      }
      & > * {
        margin-top: 10px;
      }
    }
  }
}

// Form
@media screen and (max-width: $mobile_size) {
  .label-row {
    box-sizing: border-box;
    flex-direction: column;

    label {
      width: fit-content;
      display: block;
    }

    input,
    select {
      display: block;
      max-width: none;
      box-sizing: border-box;
      height: 40px;
    }

    textarea {
      display: block;
      max-width: none;
      box-sizing: border-box;
    }

    .alert-error {
      margin-top: -10px;
    }
  }
}

@media screen and (max-width: $mobile_size) {
  .btn-bar {
    flex-direction: column;

    .btn-bar-right {
      margin-top: 10px;
    }
  }
}

// Modal

@media screen and (max-width: $mobile_size) {
  .modal-backdrop {
    .modal {
      top: 10%;
      left: 10%;
      width: calc(80% - 40px);
      padding: 20px;

      .close-btn {
        right: 13%;
        top: 5%;
        z-index: 2;
      }

      &.modal-wizard {
        input {
          width: calc(100% - 20px);
        }
      }

      &.full-mobile {
        position: absolute;
        z-index: 999999;
        border: none;
        top: 0px;
        left: 0px;
        border-radius: 0px;
        width: 100%;
        height: 100%;
        max-height: none;
        padding: 0px;

        .close-btn {
          right: 5px;
          top: 5px;
        }
      }
    }
  }
}

// List

@media screen and (max-width: $mobile_size) {
  .row-calendar {
    border-top: 1px solid $light_blue;
    padding-top: 15px;

    .read {
      flex-wrap: wrap;
      margin-bottom: 15px;

      & > div {
        margin-bottom: 3px;
      }

      .location,
      .city {
        min-width: 40%;
      }

      .description {
        width: 100%;
      }
    }
  }
}
