.content-edit {
  padding: 20px;
  padding-top: 0px;

  .title-bar {
    margin-top: 10px;
    margin-bottom: 10px;
    h2 {
      padding: 0px;
      margin: 0px;
    }
  }

  .wrapper-layout {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    .wrapper-left {
      display: flex;
      flex-direction: column;
      width: -webkit-fill-available;
    }

    .wrapper-right {
      min-width: 340px;
    }

    .box-border {
      border-radius: 8px;
      border: 1px solid $dark_blue;
      margin: 5px;
      padding: 15px;
      margin-bottom: 20px;

      .box-title {
        font-weight: bold;
        font-weight: bold;
        margin-top: -27px;
        background-color: white;
        width: fit-content;
        padding: 0px 10px;
        margin-bottom: 15px;
      }

      .box-content {
      }
    }
  }

  .prediction-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;

    .prediction-wrapper {
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      width: 250px;
      padding: 15px;

      .prediction-title {
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 12px;
      }

      .img-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;

        .media-container {
          padding: 5px;
          border-radius: 8px;

          &:hover {
            background-color: $light_gray;
          }

          &.selected {
            border: 1px solid $dark_blue;
          }

          .video-content {
            width: 240px;
            height: auto;
          }

          .img-link {
            margin-bottom: 15px;
            display: flex;
            width: 32%;

            .img-content {
              width: 240px;
              height: auto;
            }
          }
        }
      }
    }
  }

  .plan-label {
    margin-left: 0px;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 15px;
  }

  .social-type {
    margin-top: 20px;
    select {
      height: 30px;
      margin-bottom: 15px;
      padding-left: 10px;
    }
  }

  .preview {
    width: 300px;
    // border: 1px solid $dark_blue;
    margin-bottom: 15px;

    &.preview-Story {
      margin-bottom: -30px;

      .preview-header {
        position: relative;
        top: 0px;
        left: 10px;
        z-index: 0;
        color: $white;
      }
      .preview-media {
        position: relative;
        top: -40px;
        z-index: -1;

        .preview-img {
          margin-top: 0px;
        }
      }
      .preview-footer {
        display: none;
      }
    }

    &.preview-Reel {
      margin-bottom: -100px;

      .preview-header {
        display: none;
      }
      .preview-media {
        position: relative;
      }
      .preview-footer {
        .preview-btns {
          display: none;
        }

        .preview-icon-profile-reel {
          display: inline-block;
          width: 32px;
          border-radius: 32px;
          margin-right: 10px;
        }
        .preview-footer-name {
          display: inline-block;
          top: -10px;
          position: relative;
        }
        .preview-footer-desc {
          display: block;
        }

        position: relative;
        bottom: 200px;
        height: 95px;
        overflow: hidden;
        z-index: 1;
        color: $white;
        padding: 10px;
      }
    }

    .preview-header {
      width: 100%;
      display: flex;
      align-items: center;

      .preview-icon-profile {
        width: 32px;
        border-radius: 32px;
        margin-right: 10px;
      }

      .preview-name {
        font-weight: bold;
        font-size: 14px;
      }
    }

    .preview-media {
      .preview-img {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 300px;
      }
    }
    .preview-footer {
      .preview-selector {
        height: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .preview-selector-dot {
          background-color: #dadfe5;
          width: 10px;
          height: 10px;
          border-radius: 10px;
          margin: 2px;
          cursor: pointer;
          &.selected {
            background-color: #0097fd;
          }
        }
      }
      .preview-icon-profile-reel {
        display: none;
      }

      .preview-btns-img {
        height: 30px;
      }

      .preview-footer-name {
        font-weight: bold;
        font-size: 14px;
        display: inline;
        margin-right: 5px;
      }
      .preview-footer-desc {
        font-size: 14px;
        display: inline;
      }
    }
  }

  .modal-edit-wrapper {
    padding-left: 30px;
    padding-right: 30px;
    width: calc(100% - 60px);

    &.columns {
      display: flex;
      flex-direction: row;

      .modal-edit-wrapper-left {
        position: relative;
        min-width: 60%;
        text-align: left;
      }

      .modal-edit-wrapper-right {
        padding: 20px;
        padding-top: 40px;
        padding-left: 60px;
        overflow-y: scroll;
        max-height: 100vh;

        textarea {
          height: 100px;
        }
      }
    }
  }
}

.row-calendar {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  .badge {
    margin-right: 10px;
  }

  .delete {
    width: 50px;
    margin-left: 10px;
  }
  .type {
    width: 100px;
    height: 38px;
    margin-right: 10px;
  }
  .date {
    margin-right: 10px;
    width: 190px;
  }
  .location {
    margin-right: 10px;
    width: 100px;
  }
  .city {
    margin-right: 10px;
    width: 90px;
  }

  .read {
    display: flex;
    flex-direction: row;
    margin-bottom: -10px;
    max-width: 100%;

    .status {
      width: 100px;
      min-width: 100px;
    }

    .type {
      width: 60px;
      min-width: 60px;
    }

    .date {
      min-width: 60px;
      width: 60px;
    }

    .location {
      min-width: 100px;
      width: 100px;
      max-width: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap: nowrap;
      margin-right: 10px;
    }
    .city {
      min-width: 70px;
      width: 70px;
      max-width: 70px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap: nowrap;
      margin-right: 10px;
    }
    .description {
      width: max-content;
      margin-bottom: 10px;
      text-align: left;
    }
    max-width: 100%;
  }
}

.separator {
  border-top: 1px solid $dark_blue;
  width: 100%;
  margin-bottom: 10px;
}

.param-row {
  width: 100%;
  display: flex;
  flex-direction: row;

  label {
    font-weight: bold;
    min-width: 200px;
    display: inline-block;
  }
}
