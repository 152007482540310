.comments-container {
  .post-container {
    padding: 20px;
    display: flex;
    flex-direction: row;
    img {
      width: 100px;
      padding-right: 30px;
    }
  }

  .answer-content {
    background-color: $light_gray;
    border-radius: 10px;
    padding: 5px;
  }
}
