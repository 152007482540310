.navbar {
  display: flex;
  justify-content: space-between;

  &.navbar-admin-preview {
    padding-top: 10px;
  }

  .navbar-left {
    a {
      display: flex;
      vertical-align: middle;
      align-items: center;
      align-content: center;
      flex-direction: row;
      text-decoration: none;

      .navbar-logo {
        width: 30px;
        padding: 10px;
      }

      .navbar-logo-text {
        font-family: $font_title;
        color: $dark_blue;
        font-weight: bold;
        font-size: 20px;
      }
    }
  }

  .navbar-burger-content {
    display: none;
  }

  .navbar-right {
    display: flex;
    align-items: center;

    .navbar-btn {
      margin-right: 15px;
      a {
        color: $dark_blue;
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
}
